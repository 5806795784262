<label class="tw-sr-only" [for]="id">{{ "search" | i18n }}</label>
<div class="tw-relative tw-flex tw-items-center">
  <label
    [for]="id"
    aria-hidden="true"
    class="tw-absolute tw-left-2 tw-z-20 !tw-mb-0 tw-cursor-text"
  >
    <i class="bwi bwi-search bwi-fw tw-text-muted"></i>
  </label>
  <input
    #input
    bitInput
    type="search"
    [id]="id"
    [placeholder]="placeholder ?? ('search' | i18n)"
    class="tw-pl-9"
    [ngModel]="searchText"
    (ngModelChange)="onChange($event)"
    (blur)="onTouch()"
    [disabled]="disabled"
  />
</div>
